:root {
  --color-gray: rgba(255, 255, 255, 0.7);

  /* --------------- */

  --color-brown: #d6b425;
  --color-orange: #ea5d26;
  --color-black: #121212;

  /* --------------- */

  --color-green--0100: #cadcd0;
  --color-green--0500: #17a093;
  --color-green--1000: #1b9181;
  /* --------------- */

  --color-blue--0000: #f5f8fa;
  --color-blue--0100: #f3f7f9;
  --color-blue--0200: #dae0e8;
  --color-blue--0500: #1567b0;
  --color-blue--0700: #154b6c;
  --color-blue--1000: #0c1920;

  /* --------------- */

  --c-bg: var(--color-blue--0000);

  /* --------------- */

  --c-selection: var(--color-brown);
  --c-result: var(--color-orange);
  --c-primary: var(--color-blue--0500);
  --c-hover: var(--color-green--1000);
  --c-black: var(--color-black);

  /* --------------- */
}

html {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.45;
  color: var(--c-black);
  background: var(--c-bg);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  /* color: inherit; */
}

.inline-link a {
  border-bottom: 2px solid var(--c-primary);
}

.inline-link a:hover {
  border-color: var(--c-hover);
}

::selection {
  background: var(--c-selection);
  color: white;

  text-shadow: none;
}

pre,
.code,
code {
  font-family: "Source Code Pro", '"Fira code", "Fira Mono", monospace';
}

pre {
  margin: 0;
}

em {
  font-style: normal;
  font-weight: 700;
}

p,
h1,
h2,
h3 {
  margin-top: 0;
  margin-bottom: 0.4em;
}

h1,
h2,
h3 {
  font-weight: 700;
  line-height: 1.2;
}

h2,
h3 {
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

h1 {
  font-size: 1.2rem;
}

h2 {
  font-size: 1rem;
  /* font-weight: 400; */
}

h3 {
  font-size: 0.8rem;
}

#root,
.container,
body,
html {
  height: 100%;
  min-height: 100%;
}

textarea,
pre {
  tab-size: 2;
}

textarea {
  outline: 0 !important;
  display: block;
  border: none;
  width: 100%;
  resize: none;
  padding: 0;
}
