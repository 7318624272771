div[role="slider"],
button {
  /* reset */
  color: inherit;
  border: 0;
  box-shadow: none;
  background: transparent;
  text-shadow: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;

  text-align: left;

  /* style */
  /* background-color: grey; */
}

div[role="slider"]:focus,
button:focus {
  outline: 0 !important;
}

div[role="slider"]:disabled,
button:disabled {
  cursor: auto;
}
