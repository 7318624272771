.admin {
  position: fixed;
  bottom: 10rem;
  left: 0;
  background-color: gray;
  padding: 0.5rem;
  font-size: 0.7em;
}

.admin button {
  border: 1px solid black;
  padding: 0.5rem;
  border-radius: 0.3rem;
}

.admin__preview-screen {
  position: relative;
}

.admin__preview-screen svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
