.editor {
  width: 100%;
  height: 100%;
  background-color: white;
  position: relative;
}

.editor__solution {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: var(--color-blue--0200);
  padding: 0.2rem 0.5rem;
  border-radius: 0.25rem;
  z-index: 999;
  min-width: 10rem;
}

.editor__solution .editor__editor {
  font-size: 0.9rem;
}

.editor__solution-show {
  text-align: center;
}

.editor__solution:hover .editor__solution-show {
  display: none;
}

.editor__solution-text {
  display: none;
}

.editor__solution:hover .editor__solution-text {
  display: block;
  padding: 0.5rem 0.2rem;
}

.editor__editor {
  width: 100%;
  height: 100%;
  font-size: 1.3rem;
}

.editor__textarea-pre span {
  background-color: transparent !important;
}

.editor__textarea-pre {
  z-index: 8;
}

.editor__textarea-textarea {
  z-index: 9;
}

.editor__textarea-textarea::selection {
  color: var(--color-black) !important;

  -webkit-text-fill-color: var(--color-black) !important;

  background: var(--c-selection) !important;
}
