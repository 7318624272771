.callout {
  background-color: var(--color-blue--0200);

  border-left: 0.5rem solid var(--c-primary);
  padding-top: 0.75rem;
  padding-bottom: 0.7rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.25rem;
  position: relative;
}

.callout--done {
  background-color: var(--color-green--0100);
  border-left-color: var(--color-green--0500);
}

.callout__next {
  background-color: var(--c-primary);
  color: white;
  position: absolute;
  right: 0%;
  top: 100%;
  transform: translate(1rem, -1rem);
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  z-index: 9999;
}

.callout__next-icon {
  position: absolute;
  font-size: 4rem;
  left: 50%;
  top: 0.5rem;
  transform: translate(-50%, 0);
}

.callout__next-text {
  position: absolute;
  left: 50%;
  bottom: 1.5rem;
  transform: translate(-50%, 0);
  color: white;
  font-size: 0.9rem;
}

a:hover .callout__next {
  background-color: var(--c-hover);
}
