/* whole heigth */
.capter-menu {
  position: relative;
  width: 100%;
}
/* the gradient right */
.capter-menu:before,
.capter-menu:after {
  content: "";
  width: 1.25em;
  height: 100%;
  position: absolute;
  top: 0;
}

.capter-menu:before {
  left: 0;
  background: linear-gradient(
    90deg,
    var(--color-blue--0700),
    rgba(230, 230, 230, 0)
  );
}
.capter-menu:after {
  right: 0;
  background: linear-gradient(
    90deg,
    rgba(230, 230, 230, 0),
    var(--color-blue--0700)
  );
}

/* scroll-area */
.capter-menu__list {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  width: 100%;
  scrollbar-width: none; /* no-scrollbar: firefox */
}
.capter-menu__list::-webkit-scrollbar {
  display: none; /* no-scrollbar: Safari and Chrome */
}

/* at the end of the list we add space*/
.capter-menu__list:before,
.capter-menu__list:after {
  display: block;
  content: "";
  width: 1.25em;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 1.25em;
}

/* don't wrap within the items */
.capter-menu__item {
  white-space: nowrap;
  border-bottom: 2px solid transparent;
}

.capter-menu__item--active,
.capter-menu__item:hover {
  border-color: white;
}

/* space between the items */
.capter-menu__item + .capter-menu__item {
  margin-left: 1.25em;
}

.capter-menu__item--done {
  opacity: 0.7;
}
