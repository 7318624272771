.step-menu__item:hover:not(.step-menu__item--active) {
  color: var(--c-hover);
}

.step-menu__item--active {
  color: var(--c-primary);
}

.step-menu__item--done {
  opacity: 0.7;
}

.step-menu__link {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
}
