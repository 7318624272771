.video-player {
  width: 100%;
}

.video-player__video {
  position: absolute;
  right: 30%;
  bottom: 4rem;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
}

.video-player__video,
.video-player__video *:before {
  color: transparent !important;
}

.video-player video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-player__bar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.video-player__track {
  margin-left: 1rem;
  margin-right: 1rem;
}
.video-player__control {
  height: 2rem;
  display: flex;
  margin-left: 0.6rem;
  margin-bottom: 0.5rem;
  color: var(--color-blue--0200);
}

.video-player__btn {
  height: 2rem;
  width: 2rem;
  text-align: center;
  overflow: hidden;
  font-size: 1.25rem;
}

.video-player__btn:hover {
  color: var(--c-hover);
}

.video-player__time {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  font-size: 0.8rem;
}

.video-player .vjs-error {
  display: none;
}

.video-player__big-start {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(8, 31, 43, 0.9);
  z-index: 11;
}

.video-player__big-start:hover {
  color: var(--c-hover);
}

.video-player__big-start button {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.video-player__big-start button span {
  font-size: 12vmin;
}
