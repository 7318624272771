.player {
  width: 100%;
  height: 100%;
  background: var(--color-blue--1000);

  display: flex;
  flex-direction: column;
}

.player__content {
  position: relative;
  flex-grow: 1;
  height: 100%;
  display: flex;
}

.player__code {
  width: 70%;
  height: calc(100% - 4rem);
  padding: 2rem;
}

.player__preview {
  width: 30%;
  height: calc(100% - 4rem);
  padding: 2rem;
}

.player__video {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
