.preview {
  width: 100%;
  height: 100%;
}

.preview__view {
  display: flex;
  justify-content: center;
}

.svg__text {
  fill: var(--c-black);
  font-size: 16px;
}

.svg__rect {
  stroke-width: 3;
  stroke: var(--c-result);
}

.svg__paper {
  /* stroke-width: 1; */
  fill: var(--c-bg);
  stroke: var(--c-bg);
}

.preview--negative .svg__paper {
  fill: var(--color-blue--0200);
  stroke: var(--color-blue--0200);
}

.preview__error {
  padding: 1rem;
  /* margin: 1rem;
  margin-top: 2rem;
  width: calc(100% - 2rem); */
}

.preview__debug {
  font-size: 0.75em;
}

.preview__console {
  margin: 1rem;
  margin-top: 2rem;
  line-height: 1.15;
  font-size: 1.2rem;
}
