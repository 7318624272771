.text-player {
  font-size: 1.3rem;
  position: relative;
}

.part {
  display: inline-block;
  position: relative;
  color: transparent;
  white-space: pre;
}

.part--mark {
  width: 0;
  animation: blinker 1s step-start infinite;
  user-select: none;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.part--mark:after {
  content: "";
  display: block;
  width: 2px;
  height: 100%;
  background-color: white;
  top: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
}

.part--selected {
  background-color: var(--c-selection);
  color: var(--c-black);
}

.text-player__selection,
.text-player__highlight {
  position: absolute !important;
  left: 0;
  top: 0;
}

.text-player__highlight span {
  background-color: transparent !important;
}

.text-player__line span {
  white-space: pre;
}
