.viewer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.viewer__chapter-menu {
  height: 3rem;
  flex-basis: 3rem;
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  background-color: var(--color-blue--0700);
  color: white;
  display: flex;
  align-items: center;
}

.viewer__content {
  width: 100%;
  display: flex;
  flex-grow: 1;
}

.viewer__step-menu {
  max-width: 20rem;
  width: 25%;
  font-weight: 700;
}

.viewer__step {
  width: 75%;
  display: flex;
  flex-direction: column;
}
.viewer__step-menu-inner {
  padding: 2rem;
}

.viewer__teach {
  width: 100%;
  height: 45%;
  min-height: 21rem;
  color: white;
}

.viewer__teach-inner {
  height: 100%;
}

.viewer__task {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.callout--done .viewer__task-text .markdown {
  opacity: 0.8;
}

.viewer__task code {
  background-color: rgb(58, 58, 58);
  color: white;

  padding-left: 0.2rem;
  padding-right: 0.2rem;
  padding-bottom: 0.1rem;
  border-radius: 0.3rem;
}

.viewer__learn {
  width: 100%;
  height: 55%;
}

.viewer__learn > div {
  height: 100%;
}
