.track {
  height: 1.5rem;
  width: 100%;
}

div.track__range {
  height: 100%;
  position: relative;
}

div.track__range:after {
  content: "";
  height: 0.15rem;
  position: absolute;
  top: 50%;
  width: 100%;
  left: 0;
  background-color: var(--color-blue--0200);
  transform: translateY(-50%);
}

div.track__played {
  background-color: var(--c-primary);
  height: 0.2rem;
  position: absolute;
  top: 50%;
  z-index: 1 !important;
  left: 0;
  transform: translateY(-50%);
}

div[role="slider"].track__thumb {
  height: 1rem;
  width: 1rem;
  background-color: var(--color-blue--0200);
  border-radius: 50%;
  z-index: 2 !important;
}

div.track__thumb:hover {
  background-color: var(--c-hover);
}
